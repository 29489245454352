import React from 'react'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'

import styles from './post-card.module.sass'
import Link from '../Link'
import Image from '../Image'

export default function PostCard({ id, title, path, thumb, image, description }) {
  return (
    <article className={classNames(styles.postCard, description && styles.postHorizontal)}>
      <Link to={path}>
        <figure className={styles.cardImage}>
          <Image src={image || `${thumb}?${id}`} alt={title} />
        </figure>
        <div className={styles.cardContent}>
          <Markdown
            className={styles.cardTitle}
            options={{ wrapper: 'h3', forceWrapper: true }}
            children={title || ''}
          />
          {description && <p>{description}</p>}
        </div>
      </Link>
    </article>
  )
}
